import React from 'react'
import { NotFound } from '../components/base/NotFound'

const NoEncontradoPage = () => {
    return (
        <NotFound />
    )
}

export default NoEncontradoPage
